import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  z-index: 99999999999;
  background-color: RGBA(0, 0, 0, 0.8);
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-items: center;

  .close {
    animation: fadeoutprivacy 0.4s;

    @keyframes fadeinprivacy {
      from {
        transform: scale(1);
        opacity: 1;
      }

      to {
        transform: scale(0);
        opacity: 0.95;
      }
    }

    opacity: 0;
    display: none;
  }

  .on {
    animation: fadeinprivacy 0.6s;
    opacity: 1;
    overflow: auto;
    max-height: 95%;

    @keyframes fadeinprivacy {
      from {
        transform: scale(0);
        opacity: 0;
      }

      to {
        transform: scale(1);
        opacity: 0.95;
      }
    }
  }

  .content {
    border-radius: 6px;

    margin: auto;
    background-color: #fff;
    /* opacity: 0.95; */
    color: #333;
    overflow: auto;
    display: flex;
    flex-direction: column;

    @media (max-width: 900px) {
      padding-bottom: 5rem;
    }

    p {
      font-size: 1.4rem;
      font-weight: 500;
      margin-bottom: 0.6rem;
    }

    section {
      display: block;

      margin-bottom: 1.3rem;

      p {
        font-weight: 400;
        font-size: 0.875rem;
        margin-bottom: 0.6rem;
      }

      ol {
        margin-left: 1.8rem;
      }
    }

    div {
      padding: 0rem 1rem 1rem 1rem;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: auto;

      button {
        background-color: #d21e26;
        color: #fff;
        padding: 0.875rem;
        border-radius: 4px;
        flex: 1;
        gap: 4px;
        font-weight: 600;
        font-size: 1rem;

        transition: all 0.2s ease;

        &:hover {
          transform: scale(0.95);
        }
      }

      a {
        flex: 1;
        display: block;
        padding: 0.875rem;
        text-align: center;
        gap: 4px;
        background-color: transparent;
        color: #000;
        font-weight: 400;
        text-decoration: underline !important;
      }
    }
  }
`;
