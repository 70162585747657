import React from "react";
import useAlertEnchenteRs from "../../hook/useAlertEnchenteRs";

import { Container } from "./styles";

export function AlertEnchenteRs() {
  const { cookiesAlertBillet, setCookiesAlertBillet } = useAlertEnchenteRs();

  if (cookiesAlertBillet) return null;

  return (
    <Container>
      <nav className={`content ${!cookiesAlertBillet ? "on" : "close"}`}>
        <section>
          <img
            src="https://alpar.sfo3.digitaloceanspaces.com/emailmarketing/comuniacado-enchente-rs.jpg"
            alt="comunicado"
            style={{
              height: `80vh`,
            }}
          />{" "}
        </section>

        <div>
          <button type="button" onClick={() => setCookiesAlertBillet(true)}>
            Eu entendo
          </button>
          <a
            // eslint-disable-next-line
            target="_blank"
            href="https://alpar.sfo3.digitaloceanspaces.com/emailmarketing/comuniacado-enchente-rs.jpg"
            className="profile-details"
          >
            Saber mais
          </a>
        </div>
      </nav>
    </Container>
  );
}
