import React from "react";

import { PanelAndDetailAndCreateHeader } from "../../../components/panel/PanelAndDetailAndCreateHeader";
import { PanelLayout } from "../../../components/panel/PanelLayout";
import { useContacts } from "../../../hook/queries/useContact";
import { Card, InnerHtml } from "../../Welcome/style";

export default function () {
  const { data: getContacts } = useContacts(1, ``, 100);

  return (
    <PanelLayout>
      <PanelAndDetailAndCreateHeader title="Lista de contatos" />

      {getContacts?.contacts.map((contact) => (
        <Card key={contact.id}>
          <div>
            <h3>{contact.title}</h3>
            <InnerHtml dangerouslySetInnerHTML={{ __html: contact.text }} />
          </div>
        </Card>
      ))}
    </PanelLayout>
  );
}
