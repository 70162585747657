import { useEffect, useState } from "react";
import * as localCookiesAlertEnchenteRs from "../service/localStorage/alertEnchenteRs";

export default function useAlertEnchenteRs() {
  const [cookiesAlertBillet, setCookiesAlertBillet] = useState(false);

  useEffect(() => {
    const cookiesConsentData = localCookiesAlertEnchenteRs.get();

    if (cookiesConsentData && cookiesConsentData.date) {
      const dateBeforeDays = new Date();
      dateBeforeDays.setDate(dateBeforeDays.getDate() - 1);

      if (new Date(cookiesConsentData.date) < dateBeforeDays) {
        setCookiesAlertBillet(false);
      } else {
        setCookiesAlertBillet(true);
      }
    }

    // eslint-disable-next-line
  }, [localCookiesAlertEnchenteRs.get()]);

  useEffect(() => {
    if (cookiesAlertBillet) {
      localCookiesAlertEnchenteRs.set({ date: new Date() });
    }
  }, [cookiesAlertBillet]);

  return { cookiesAlertBillet, setCookiesAlertBillet };
}
